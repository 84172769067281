import { Button, Input, Checkbox, Typography } from '@material-tailwind/react';
import { ArrowRight } from 'react-feather';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';

const Start = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    navigate({
      pathname: '/call',
      search: createSearchParams(Object.fromEntries(formData)).toString(),
    });
  };

  return (
    <div className="p-8 max-w-2xl mx-auto">
      <Typography variant="h3" className="text-center font-bold">
        Let&apos;s get you set up
      </Typography>

      <form onSubmit={handleSubmit} className="mt-8 flex flex-col gap-4">
        <Input label="Full Name" name="name" required />
        <Input label="Email" name="email" type="email" required />

        <div>
          <Input label="Phone Number" name="phone" maxLength={16} placeholder="e.g., +1 123-456-7890" required />
          <Typography className="mt-2 text-xs font-normal text-blue-gray-500">Include your country code for international numbers.</Typography>
        </div>

        <div>
          <Checkbox
            label={
              <Typography variant="small" color="black">
                I have read and understood the privacy policy <span className="text-red-900">*</span>
              </Typography>
            }
            name="privacy"
            required
          />

          <Typography variant="small" color="gray" className="ml-11 text-xs">
            By checking this box, you agree to our{' '}
            <a href="https://www.usehana.com/privacy" target="_blank" className="text-blue-gray-500 underline" rel="noopener noreferrer">
              Privacy Policy
            </a>
            , which outlines how we collect, use, and protect your personal information.
          </Typography>
        </div>

        <div>
          <Checkbox
            label={
              <Typography variant="small" color="black">
                I allow Hana to use my device&apos;s microphone for conversations <span className="text-red-900">*</span>
              </Typography>
            }
            name="microphone"
            required
          />
          <Typography variant="small" color="gray" className="ml-11 text-xs">
            This permission allows Hana to access your microphone for voice conversations. Your voice data is processed securely and not stored long-term.
          </Typography>
        </div>

        <div>
          <Checkbox
            label={
              <Typography variant="small" color="black">
                I understand that Hana is not made to substitute medical advice and is not a medical diagnostic tool <span className="text-red-900">*</span>
              </Typography>
            }
            name="medical"
            required
          />
          <Typography variant="small" color="gray" className="ml-11 text-xs">
            Hana is designed to provide support and insights, but should not be used as a replacement for professional medical advice, diagnosis, or treatment.
          </Typography>
        </div>

        <div className="flex justify-between mt-4">
          <Link to="/">
            <Button variant="outlined" size="sm">
              Back
            </Button>
          </Link>

          <Button type="submit" size="sm" className="flex items-center">
            Begin Your Journey
            <ArrowRight className="ml-2" width={24} />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Start;

import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@material-tailwind/react';
import Home from './Pages/Home.jsx';
import Start from './Pages/Start.jsx';
import Call from './Pages/Call.jsx';
import Report from './Pages/Report.jsx';

const Container = () => {
  return (
    <div className="min-h-screen bg-[#E8E7E7] p-4 sm:p-8">
      <main className="max-w-4xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden">
        <div className="bg-gradient-to-r from-[#A4D4EA] via-[#BED1F9] via-[#DEE2ED] via-[#FAF0E6] to-[#FDF6E3] flex p-4">
          <img src="/logo.png" alt="Hana Logo" className="h-10 mr-4" aria-label="Hana Logo" />
          <h1 className="text-3xl font-bold">Your Mental Health Companion</h1>
        </div>
        <Outlet />
      </main>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Container />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/start',
        element: <Start />,
      },
      {
        path: '/call',
        element: <Call />,
      },
      {
        path: '/report/:reportId',
        element: <Report />,
      },
    ],
  },
]);

const App = () => {
  const theme = {
    button: {
      styles: {
        base: {
          initial: {
            textTransform: 'none',
          },
        },
        sizes: {
          sm: {
            fontSize: 'text-base',
          },
          md: {
            fontSize: 'text-xl',
          },
        },
      },
    },
    input: {
      defaultProps: {
        size: 'lg',
      },
    },
  };

  return (
    <ThemeProvider value={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
